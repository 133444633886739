// TODO: This should be installed with bitbucket/github package
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactionRenderer, DisplayMatrix } from 'chemotion_reaction_rendering';

export default class ReactionSvgFetcher {
  static fetchByReaction(elnReaction) {
    console.log('elnReaction', elnReaction);
    console.log('Calling Convert ELN Reaction');

    return ReactionRenderer.convertELNReaction(elnReaction).then((reactionArray) => {
      console.log('Got reaction Array', reactionArray);
      const displayMatrix = DisplayMatrix.createDisplayMatrixFromELNReaction(elnReaction);

      const rr = new ReactionRenderer(displayMatrix, reactionArray);
      console.log(rr);

      return { reaction_svg: rr.renderReaction() };
    });
  }
}
